import React from "react"

import Layout from "../components/layout"
import Typography from "@material-ui/core/Typography";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        border: 0,
    },
    title:{
        fontFamily: 'Helvetica Neue sans-serif, serif',
        lineHeight: 0.8,
        fontWeight: 'bold',
        color: 'seashell',
        padding: '15rem 0rem 0 0',
        background: '-webkit-linear-gradient(-45deg, #F7DE05, #DA8E00, #EDAC06, #F7DE05, #ECB802, #DAAF08, #B67B03, #DA8E00, #EDAC06, #F7DE05, #ECB802, #EDAC06)',
        WebkitBackgroundClip:'text',
        WebkitTextFillColor:'transparent',
        zIndex:199,
        fontSize: '8vw',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10vw',
        },


    },
    subtitle:{
        fontFamily: 'Helvetica Neue sans-serif, serif',
        padding: '1rem 0rem 0 0',
        color: '#f2f2f2',
        fontSize: '2rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
        textShadow: '1px 1px 2px #080705'
    }
}));

export default　function IndexPage() {

    const classes = useStyles();
    return (
        <Layout bgImage={true}>
            <CssBaseline/>
            <Grid container
                  alignItems="center"
                  justify="center"
                  style={{ minHeight: '100vh' }}>
                <Grid >
                    <Typography variant="h1" className={classes.title}>
                        Aoi MichellE
                    </Typography>
                    <Typography variant="h4" className={classes.subtitle}>
                        Acoustic guitarist
                    </Typography>
                </Grid>
            </Grid>
        </Layout>
    );
}
